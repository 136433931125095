@import '@/styles/variables';

.container {
  position: relative;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  padding: 10px 0;
  color: $white;
  background-color: $gray-800;
}

.containerInner {
  display: flex;
  flex-grow: 0;
  flex-wrap: wrap;
  gap: 10px 0;
  justify-content: center;
  margin: 0 3rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }
}

.row {
  display: flex;
  flex-basis: 100%;
  gap: 25px;
  justify-content: center;
  text-align: center;

  * {
    font-size: 14px;
  }

  a,
  button {
    color: $gray-100;

    &:hover {
      color: $gray-500;
      text-decoration: underline;
    }
  }
}

.mediavineFooterButton {
  background: none;
  border: none;
}

.scm {
  color: #0fd184 !important;
}

.vertical {
  flex-direction: column;
  gap: 12.5px;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }
}

.wotc {
  max-width: 700px;
}
