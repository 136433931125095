@import '@/styles/variables';

.buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.buttonRow {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border: none;
}

.cards {
  display: flex;
  flex-grow: 1;
  flex-flow: row wrap;
  align-content: flex-start;
  max-width: 100%;
  padding: 0.25rem;
  overflow: auto scroll;
}

.clipboard {
  position: fixed;
  top: 56px;
  right: 0;
  bottom: 0;
  z-index: $z-index-clipboard;
  display: block;
  height: 40px;
  padding: 40px 0 0;
  color: #333;
  border-radius: 5px;

  .buttons {
    display: none;
  }

  .clipboardBorder {
    border: 2px solid $card-border-color;
    border-radius: 5px;
  }

  .cards {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }

  &.open {
    left: 0;
    display: block;
    width: 100%;
    height: 344px;
    padding: 0.5rem;
    background-color: $card-bg;
    border-radius: 0;

    .buttons {
      display: flex;
    }

    .cards {
      display: flex;
    }

    .imageViewer {
      visibility: visible;
    }

    .toggle {
      border-radius: 0;
    }

    @include media-breakpoint-down(md) {
      height: calc(100dvh - 56px - 50px); // Account for navbar and bottom ad

      &.patron {
        height: calc(100dvh - 56px); // Account for navbar
      }
    }

    @include media-breakpoint-up(lg) {
      top: 50px;
      padding: 10px;
    }
  }
}

.error {
  padding: 6px 12px;
  margin-top: 0.5rem;
}

.image {
  width: 200px;

  /* Magic numbers - same size as is rendered on edhrec normally */
  height: 278px;
  border-radius: 4.25%;
}

.image.empty {
  visibility: hidden;
}

.imageWrapper {
  display: none;
  flex-direction: column;
  margin-left: 0.5rem;

  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

.imageViewer {
  visibility: hidden;
  width: 200px;
  height: 278px;
  overflow: auto;
  background-color: var(--bs-secondary-bg);
  border-radius: 4px;
}

.toggle {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: $white;
  cursor: pointer;
  background: $gray-800;
  border-radius: 0 0 0 5px;

  @include media-breakpoint-up(lg) {
    visibility: hidden;
  }
}

.mobileButtons {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  width: 100%;

  & .big {
    flex: 1;
  }

  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.mobileImageViewer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $z-index-clipboard;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 30%);
  transition: background-color 0.2s ease-in-out;

  &.open {
    @include media-breakpoint-down(md) {
      display: block;
    }
  }
}

.mobileImage {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 488px;
  max-height: 680px;
  transform: translate(-50%, -50%);

  img {
    display: none;
    width: 100%;
    height: 100%;
    border-radius: 4.25%;

    .open & {
      display: block;
    }
  }
}
