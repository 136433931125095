.react-bootstrap-table td > .selection-input-4,
.react-bootstrap-table th > .selection-input-4 {
  margin: 0 !important;
}

th .caret-4-asc::after {
  content: '' !important;
}

th .caret-4-asc::before {
  content: '\2193' !important;
}

th .caret-4-desc::after {
  content: '\2191' !important;
}

th .caret-4-desc::before {
  content: '' !important;
}

th .order-4::before {
  content: '' !important;
}

th .order-4::after {
  content: '' !important;
}

.order,
.react-bootstrap-table-sort-order {
  display: none;
}
