@import '@/styles/variables';

.icon {
  height: 18px;

  [data-bs-theme='dark'] & {
    filter: invert(100%);
  }
}

.image {
  position: relative;
  width: 32px;
  height: 24px;
  background-position: center;
  background-size: cover;
}

.left {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  margin-right: 10px;
}

.right {
  overflow: hidden;
}
