@import '../variables';

#consumer-privacy-footer-wrapper {
  padding-bottom: 10px;
  color: $black;
  background-color: $gray-800;

  .consumer-privacy {
    display: flex;
    justify-content: center;

    .consumer-privacy-btn.consumer-privacy-footer-btn {
      z-index: 0 !important;
      padding: 0;
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      color: #f3f3f3;
      text-transform: lowercase;

      &:hover {
        color: #a9a9a9;
        text-decoration: underline;
      }

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}

#grow-footer-account-link {
  padding-bottom: 10px;
  margin: 0 !important;

  button {
    font-size: 14px;
    font-weight: 400;
    color: #f3f3f3;
  }
}

.mvCardList {
  display: flex;
  flex-direction: column;

  .mvSidebar1 {
    display: none;
    width: 0;

    @media (width >= 1003px) {
      display: block;
      flex: 0 0 300px;
      width: 300px;
    }
  }
}

.mvHomeSidebar {
  margin-top: 0.5rem;
}

.mvLeaderboard {
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;

  @media screen and (width >= 728px) {
    display: block;
  }
}

// Covers the Patreon ad and centers the MV ad
.mv-ad-box,
.mv-rail-frame-250 {
  z-index: $z-index-tooltip;
  width: 100% !important;
  height: 100% !important;
  background-color: var(--bs-card-bg) !important;
}

.mv-rail-frame-440 {
  width: 100%;
}

.mv-rail-slide-250,
.mv-rail-slide-440 {
  display: flex !important;
}

// Force sticky ad to start sticking before its beneath the CardLists header
.mv-rail-sticky-250 {
  top: 100px !important;
}

#ps_caff {
  display: none;
}

#sovrn_beacon {
  display: none;
}

/* .universalPlayer__right {
  right: 0 !important;
} */

div#fixed_container_bottom {
  // z-index: 2147483535;
  z-index: $z-index-navbar - 2 !important;
}
