@import '@/styles/variables';

.container {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  z-index: $z-index-toast;
  display: none;
  transform: translate(-50%);

  @include media-breakpoint-up(sm) {
    display: block;
  }
}
