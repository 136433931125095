@import '@/styles/variables';

.addButton {
  color: $green;
}

.item {
  margin-left: 4px;
  cursor: pointer;
}

.illegal {
  color: $red !important;
}

.buttons {
  display: flex;
  flex-direction: row;
  margin-right: 0.25rem;
  margin-left: auto;

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 40px;
    cursor: pointer;
  }

  @include media-breakpoint-up(md) {
    margin-right: 30px;

    span {
      width: 20px;
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;

  @include media-breakpoint-up(md) {
    width: 300px;
    font-size: 14px;
  }
}

.rec {
  font-style: italic;
}

.link {
  margin-right: 1.5px;
}

.removeButton {
  color: $red;
}

.commanderButton {
  color: gold !important;
}
