@import '@/styles/variables';

.brandSmall {
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  width: 100%;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.buttonContainer {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
}

.communityIcon {
  margin-right: 5px;
}

.commanderSpellbook {
  margin-bottom: 2.5px;
}

.displayName {
  display: flex;
  align-items: center;
  max-width: 160px;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.icon {
  padding: 0.5rem 0;

  @include media-breakpoint-up(lg) {
    padding: 0.5rem 1rem;
  }
}

.indent span {
  margin-left: 24px;
}

.indentDouble span {
  margin-left: 56px;
}

.logo {
  height: 40px;
}

.manaRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .text {
    margin-left: 0.5rem;
  }
}

.navbar {
  z-index: $z-index-navbar;
  text-align: center;
  background-color: $gray-800 !important;

  @include media-breakpoint-up(lg) {
    position: fixed !important;
    top: 0;
    right: 0;
    left: 0;
    height: 50px;
    border-bottom: 1px solid $gray-700;
  }
}

.navbar2 {
  display: none;
  padding-right: 50px;

  @include media-breakpoint-up(lg) {
    z-index: $z-index-navbar-2;
    display: flex;
    height: 40px;
  }
}

.navbar2link {
  margin: 0 0.25rem;
}

.recs {
  margin-bottom: 0.5rem;

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }
}

.search {
  width: 218px !important; // Max width before buttons run out of room, be sure to test Mac OS if changing this

  @include media-breakpoint-up(lg) {
    width: 231px !important;
  }

  @include media-breakpoint-up(xl) {
    width: 300px !important;
  }
}

.searchSmall {
  z-index: $z-index-navbar-2;
  display: flex;
  width: 100%;
  max-width: 400px;
}

.select {
  width: 200px;
}

.setIcon {
  width: 20px;
  margin-right: 12px;
  font-size: 18px;
  text-align: center;

  [data-bs-theme='dark'] & {
    filter: invert(100%);
  }
}

.signUp {
  display: block;

  @include media-breakpoint-up(lg) {
    display: none;
  }

  // TODO test this threshold on Mac OS
  @media (width >= 1040px) {
    display: block;
  }
}

.subDropdown {
  a {
    padding: 0.25rem 1rem !important;
    color: var(--bs-body-color) !important;
  }

  &:hover {
    background-color: var(--bs-tertiary-bg) !important;
  }
}

.spacer {
  width: 100%;

  @include media-breakpoint-up(lg) {
    height: 50px;
  }
}

.typeahead {
  z-index: $z-index-navbar-dropdown;
  width: 100%;

  :global(.rbt-aux) {
    // This renders while loading the typeahead and bumps the mobile toolbar if not hidden
    display: none;
  }

  // Pretend the typeahead is always light mode (excluding dropdown)
  :global(.rbt-input-main) {
    color: $body-color;
    background-color: $body-bg;
    border-color: $border-color;

    &::placeholder {
      color: $body-secondary-color;
      opacity: 1;
    }
  }
}

.salt {
  width: 20px;
  height: 20px;
  margin-right: 0.4rem;
  filter: invert(100%) sepia(100%) saturate(100000%);
}

.saltMobile {
  width: 20px;
  height: 20px;
  margin-right: 0.4rem;
  filter: invert(100%) sepia(100%) saturate(100000%);
}
