@import '../variables';

.card,
.modal-body {
  input,
  input:focus,
  select,
  select:focus {
    background-color: var(--bs-tertiary-bg);
  }
}

.dropdown-menu {
  z-index: $z-index-navbar-dropdown;

  * {
    z-index: $z-index-navbar-dropdown;
  }

  &.show {
    z-index: $z-index-navbar-2;
  }
}

.dropdown.nav-item .dropdown.nav-item.show {
  padding: 0 1rem 0.5rem;

  @include media-breakpoint-up(lg) {
    padding: 0;
  }
}

.nav-item.show.dropdown {
  z-index: $z-index-navbar-dropdown-submenu;
}

.navbar-nav {
  align-items: center;
}

.nav-tabs {
  border-bottom: 0 !important;

  .nav-item {
    z-index: $z-index-tooltip;
  }

  .nav-link.active {
    box-shadow: $box-shadow-sm;
  }
}
