.aligncenter {
  max-width: 100%;
  height: auto;
}

img.aligncenter {
  display: block;
  width: auto;
  margin: 20px auto;
}

.display_preview {
  display: none;

  @include media-breakpoint-up(xs) {
    display: block;
  }
}

#author-bio-box {
  padding: 0.5rem;
  margin-bottom: 1rem;
  color: var(--bs-body-color) !important;
  background: var(--bs-secondary-bg) !important;
  border-top: 1px solid $card-border-color !important;
  border-bottom: 1px solid $card-border-color !important;

  .bio-gravatar {
    float: left;

    img {
      padding: 3px;
      margin-right: 1rem;
      background: var(--body-bg);
      border: 1px solid var(--body-bg);
      border-radius: 3px;
    }
  }

  a {
    color: var(--bs-body-color) !important;
  }
}
